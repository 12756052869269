/** Used to define container behavior: width, position: fixed etc... **/
/* .Toastify__toast-container {
} */

/** Used to define the position of the ToastContainer **/
/* .Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
} */

/** Classes for the displayed toast **/
.Toastify__toast {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.102);
  transition-property: box-shadow;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;
  border-radius: 0px;
}
.Toastify__toast:hover {
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.19);
}
/* .Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
} */

/** Classes for the close button. Better use your own closeButton **/
/* .Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
} */

/** Classes for the progress bar **/
/* .Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
} */
