@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Inter:wght@400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;600&display=swap');


@font-face {
  font-family: "RobotoLight";
  src: url("/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "ViennaGaramondLight-Regular";
  src: url("/fonts/ViennaGaramondLight-Regular.otf") format("opentype"),
    url("/fonts/ViennaGaramondLight-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "ViennaGaramondLight-Italic";
  src: url("/fonts/ViennaGaramondLight-Italic.otf") format("opentype"),
    url("/fonts/ViennaGaramondLight-Italic.woff2") format("woff2");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  border-radius: 0px;
  margin: 0px;
  /* experimental */
  overscroll-behavior-y: none;
}

@media print {

  /* Hide elements with the "hide-on-print" class */
  .hide-on-print {
    display: none !important;
  }
}


input {
  font-family: inherit;
}

mark {
  background-color: rgba(232, 201, 44, 0.5);
}

[contenteditable] {
  -webkit-user-select: text;
  -moz-user-select: text;
   -ms-user-select: text;
       user-select: text;
}

/* prevents autozoom on input focus on Safari for iOS */
@supports (-webkit-overflow-scrolling: touch) {
  input {
    font-size: 16px;
  }
}

/* -------------------------------------------------- */
/*                       Common                       */
/* -------------------------------------------------- */

/* TODO move into styled comps  */
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  line-height: 20px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 500;
  overflow: hidden;
  padding: 2px 5px;
  height: 35px;
  white-space: nowrap;
}

.tg .tg-0lax {
  text-align: left;
  vertical-align: top;
}

.incorrect {
  color: red;
}

@media (max-width: 479px) {
  .tg {
    font-size: 12px;
    height: 35px;
  }
}


/* -------------------------------------------------- */
/*                       Search                       */
/* -------------------------------------------------- */

.ais-Highlight-highlighted {
  background-color: rgba(232, 201, 44, 0.5);
}

.ais-Pagination-item {
  margin-right: 3px;
  margin-left: 3px;
}

.full-text-highlight {
  background-color: rgba(232, 201, 44, 0.5);
}

.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
}

.ais-Hits-item {
  margin-bottom: 1em;
}

.ais-RefinementList-label {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.ais-RefinementList-checkbox {
  background-color: transparent;
  background-color: initial;
  cursor: default;
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 0px;
  padding: 0;
  padding: initial;
  border: medium none currentColor;
  border: initial;
}

.ais-RefinementList-count {
  padding: 0.07rem 0.2rem;
  font-size: 0.8rem;
  color: #3a4570;
  background-color: #c2c2c2;
  border-radius: 4px;
}

/* Circle Loader */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 1.5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgb(190, 190, 190);
  border-right: 1.1em solid rgb(190, 190, 190);
  border-bottom: 1.1em solid rgb(190, 190, 190);
  border-left: 1.1em solid #000000;
  transform: translateZ(0);
  animation: circle-loader 1.1s infinite linear;
}

@keyframes circle-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Scribe Spinner */
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.scribe-spinner {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2.4px solid #d5d5dd;
  border-top-color: #000;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}

.scribe-spinner::before {
  content: "";
  display: block;
  position: absolute;
  width: 30%;
  height: 100%;
  -webkit-clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
          clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
}
.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-Results-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-ToggleRefinement-list {
  margin: 1px;
  padding: 0;
  list-style: none;
}

.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-submit,
.ais-SearchBox-reset,
.ais-VoiceSearch-button {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-GeoSearch-redo::-moz-focus-inner,
.ais-GeoSearch-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadPrevious::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner,
.ais-VoiceSearch-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-GeoSearch-redo[disabled],
.ais-GeoSearch-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadPrevious[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-submit[disabled],
.ais-SearchBox-reset[disabled],
.ais-VoiceSearch-button[disabled] {
  cursor: default;
}

.ais-Breadcrumb-list,
.ais-Breadcrumb-item,
.ais-Pagination-list,
.ais-RangeInput-form,
.ais-RatingMenu-link,
.ais-PoweredBy {
  display: flex;
  align-items: center;
}

.ais-GeoSearch,
.ais-GeoSearch-map {
  height: 100%;
}

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em;
}

.ais-PoweredBy-logo {
  display: block;
  height: 1.2em;
  width: auto;
}

.ais-RatingMenu-starIcon {
  display: block;
  width: 20px;
  height: 20px;
}

.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  display: none;
}

.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0px;
  width: 100%;
}

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px;
}

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #aaa;
}

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: #333;
}

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: grab;
}

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: #aaa;
}

.rheostat-marker--large {
  height: 9px;
}

.rheostat-value {
  margin-left: 50%;
  padding-top: 15px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
}

.rheostat-tooltip {
  margin-left: 50%;
  position: absolute;
  top: -22px;
  text-align: center;
  transform: translateX(-50%);
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: auto;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: auto;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
/** Used to define container behavior: width, position: fixed etc... **/
/* .Toastify__toast-container {
} */

/** Used to define the position of the ToastContainer **/
/* .Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
} */

/** Classes for the displayed toast **/
.Toastify__toast {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.102);
  transition-property: box-shadow;
  transition-duration: 150ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-delay: 0ms;
  border-radius: 0px;
}
.Toastify__toast:hover {
  box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.19);
}
/* .Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
}
.Toastify__toast--success {
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
} */

/** Classes for the close button. Better use your own closeButton **/
/* .Toastify__close-button {
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
} */

/** Classes for the progress bar **/
/* .Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
} */

/**
 * VS theme by Andrew Lock (https://andrewlock.net)
 * Inspired by Visual Studio syntax coloring
 */

code[class*="language-"],
pre > code[class*="language-"] {
  font-size: 1em;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection, code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  background: #c1def1;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: #c1def1;
}
/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.2em;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #f8f8f8;
  border: 1px solid #dddddd;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #008000;
  font-style: italic;
}

.token.namespace {
  opacity: 0.7;
}

.token.string {
  color: #a31515;
}

.token.punctuation,
.token.operator {
  color: #393a34; /* no highlight */
}

.token.url,
.token.symbol,
.token.number,
.token.boolean,
.token.variable,
.token.constant,
.token.inserted {
  color: #36acaa;
}

.token.atrule,
.token.keyword,
.token.attr-value,
.language-autohotkey .token.selector,
.language-json .token.boolean,
.language-json .token.number,
code[class*="language-css"] {
  color: #0000ff;
}

.token.function {
  color: #393a34;
}

.token.deleted,
.language-autohotkey .token.tag {
  color: #9a050f;
}

.token.selector,
.language-autohotkey .token.keyword {
  color: #00009f;
}

.token.important {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.class-name,
.language-json .token.property {
  color: #2b91af;
}

.token.tag,
.token.selector {
  color: #800000;
}

.token.attr-name,
.token.property,
.token.regex,
.token.entity {
  color: #ff0000;
}

.token.directive.tag .tag {
  background: #ffff00;
  color: #393a34;
}

/* overrides color-values for the Line Numbers plugin
  * http://prismjs.com/plugins/line-numbers/
  */
.line-numbers.line-numbers .line-numbers-rows {
  border-right-color: #a5a5a5;
}

.line-numbers .line-numbers-rows > span:before {
  color: #2b91af;
}

/* overrides color-values for the Line Highlight plugin
 * http://prismjs.com/plugins/line-highlight/
 */
.line-highlight.line-highlight {
  background: rgba(193, 222, 241, 0.2);
  background: linear-gradient(to right, rgba(193, 222, 241, 0.2) 70%, rgba(221, 222, 241, 0));
}

.w-tc-editor {
  font-family: inherit;
  font-size: 12px;
}
.w-tc-editor-text,
.w-tc-editor-preview {
  min-height: 16px;
}
.w-tc-editor-preview pre {
  margin: 0;
  padding: 0;
  white-space: inherit;
  font-family: inherit;
  font-size: inherit;
}
.w-tc-editor-preview pre code {
  font-family: inherit;
}
.w-tc-editor code[class*='language-'] .token.cdata,
.w-tc-editor pre[class*='language-'] .token.cdata,
.w-tc-editor code[class*='language-'] .token.comment,
.w-tc-editor pre[class*='language-'] .token.comment,
.w-tc-editor code[class*='language-'] .token.doctype,
.w-tc-editor pre[class*='language-'] .token.doctype,
.w-tc-editor code[class*='language-'] .token.prolog,
.w-tc-editor pre[class*='language-'] .token.prolog {
  color: #90a4ae;
}
.w-tc-editor code[class*='language-'] .token.punctuation,
.w-tc-editor pre[class*='language-'] .token.punctuation {
  color: #9e9e9e;
}
.w-tc-editor code[class*='language-'] .namespace,
.w-tc-editor pre[class*='language-'] .namespace {
  opacity: 0.7;
}
.w-tc-editor code[class*='language-'] .token.boolean,
.w-tc-editor pre[class*='language-'] .token.boolean,
.w-tc-editor code[class*='language-'] .token.constant,
.w-tc-editor pre[class*='language-'] .token.constant,
.w-tc-editor code[class*='language-'] .token.deleted,
.w-tc-editor pre[class*='language-'] .token.deleted,
.w-tc-editor code[class*='language-'] .token.number,
.w-tc-editor pre[class*='language-'] .token.number,
.w-tc-editor code[class*='language-'] .token.symbol,
.w-tc-editor pre[class*='language-'] .token.symbol {
  color: #e91e63;
}
.w-tc-editor code[class*='language-'] .token.attr-name,
.w-tc-editor pre[class*='language-'] .token.attr-name,
.w-tc-editor code[class*='language-'] .token.builtin,
.w-tc-editor pre[class*='language-'] .token.builtin,
.w-tc-editor code[class*='language-'] .token.char,
.w-tc-editor pre[class*='language-'] .token.char,
.w-tc-editor code[class*='language-'] .token.inserted,
.w-tc-editor pre[class*='language-'] .token.inserted,
.w-tc-editor code[class*='language-'] .token.selector,
.w-tc-editor pre[class*='language-'] .token.selector,
.w-tc-editor code[class*='language-'] .token.string,
.w-tc-editor pre[class*='language-'] .token.string {
  color: #22863a;
}
.w-tc-editor code[class*='language-'] .style .token.string,
.w-tc-editor pre[class*='language-'] .style .token.string,
.w-tc-editor code[class*='language-'] .token.entity,
.w-tc-editor pre[class*='language-'] .token.entity,
.w-tc-editor code[class*='language-'] .token.operator,
.w-tc-editor pre[class*='language-'] .token.operator,
.w-tc-editor code[class*='language-'] .token.url,
.w-tc-editor pre[class*='language-'] .token.url {
  color: #795548;
}
.w-tc-editor code[class*='language-'] .token.atrule,
.w-tc-editor pre[class*='language-'] .token.atrule,
.w-tc-editor code[class*='language-'] .token.attr-value,
.w-tc-editor pre[class*='language-'] .token.attr-value,
.w-tc-editor code[class*='language-'] .token.attr-name,
.w-tc-editor pre[class*='language-'] .token.attr-name,
.w-tc-editor code[class*='language-'] .token.property,
.w-tc-editor pre[class*='language-'] .token.property,
.w-tc-editor code[class*='language-'] .token.keyword,
.w-tc-editor pre[class*='language-'] .token.keyword {
  color: #3f51b5;
}
.w-tc-editor code[class*='language-'] .token.function,
.w-tc-editor pre[class*='language-'] .token.function {
  color: #f44336;
}
.w-tc-editor code[class*='language-'] .token.important,
.w-tc-editor pre[class*='language-'] .token.important,
.w-tc-editor code[class*='language-'] .token.regex,
.w-tc-editor pre[class*='language-'] .token.regex,
.w-tc-editor code[class*='language-'] .token.variable,
.w-tc-editor pre[class*='language-'] .token.variable {
  color: #ff9800;
}
.w-tc-editor code[class*='language-'] .token.bold,
.w-tc-editor pre[class*='language-'] .token.bold,
.w-tc-editor code[class*='language-'] .token.important,
.w-tc-editor pre[class*='language-'] .token.important {
  color: #333;
}
.w-tc-editor code[class*='language-'] .token.tag,
.w-tc-editor pre[class*='language-'] .token.tag {
  color: #22863a;
}
.w-tc-editor code[class*='language-'] .attr-name,
.w-tc-editor pre[class*='language-'] .attr-name {
  color: #005cc5;
}
.w-tc-editor code[class*='language-'] .token.selector .class,
.w-tc-editor pre[class*='language-'] .token.selector .class,
.w-tc-editor code[class*='language-'] .token.class-name,
.w-tc-editor pre[class*='language-'] .token.class-name {
  color: #6f42c1;
}

.yt-lite {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
}
.yt-lite.lyt-activated::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite::after {
  content: "";
  display: block;
  padding-bottom: var(--aspect-ratio);
}
.yt-lite > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.yt-lite > .lty-playbtn {
  width: 70px;
  height: 46px;
  background-color: #212121;
  z-index: 1;
  opacity: 0.8;
  border-radius: 14%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.yt-lite:hover > .lty-playbtn {
  background-color: red;
  opacity: 1;
}
.yt-lite > .lty-playbtn::before {
  content: "";
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 11px 0 11px 19px;
}
.yt-lite > .lty-playbtn,
.yt-lite > .lty-playbtn::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.yt-lite.lyt-activated {
  cursor: unset;
}
.yt-lite.lyt-activated::before,
.yt-lite.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

